<template>
    <main>
        <HeaderTab :title="$t('menu.admin.manage_stallion_id')" />

		<div id="content">
			<div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="box">
							<form @submit.prevent>
								<div class="row align-items-end" >
                                    <div class="col">
										<label>Rechercher une adresse e-mail ou une licence <font-awesome-icon v-if="spinner" :icon="['fal', 'circle-notch']" spin/></label>
										
										<b-input-group>
											<b-form-input v-model="user_search" :placeholder="$t('compte.email')" type="email"></b-form-input>

											<template #append>
												<b-button @click.prevent="validForm()" :disabled="spinner || working || entities.length == 0" variant="primary">
													<font-awesome-icon v-if="working" :icon="['fal', 'circle-notch']" spin/>
													Enregistrer
												</b-button>
											</template>
										</b-input-group>
                                    </div>
                                </div>

								<div v-for="(entity, key) in entities" :key="'entity_'+key" class="border-bottom py-2">
                                    <div class="row">
                                        <label class="col">Entité de facturation: {{ entity.tiers_rs }}</label>
                                    </div>
                                    <div class="row">
										<div class="col">
											<b-input 
                                                id="entity_stallion_number"
                                                :value="`${entity.tiers_rs} (${entity.tiers_mail})`"
												disabled
											/>
                                        </div>

                                        <div class="col">
											<b-input id="entity_stallion_number" v-model="entity.tiers_stallion_number" />
                                        </div>

                                        <div class="col">
                                        	<e-select
                                        		v-if="entity.carnet"
												track-by="code"
												label="label"
												:options="carnet_status"
												:allow-empty="false"
												:show-labels="false"
												v-model="entity.carnet.horsedpsstatus_status"
											/>
                                        </div>

                                        <div class="col">
                                        	<b-form-checkbox v-model="entity.tiers_sanitaire" name="check-button" switch>
										    	Sanitaire
										    </b-form-checkbox>
                                        </div>
                                    </div>
								</div>
							</form>
						</div>
					</div>
				</div>

				<!-- <div class="row">
					<b-button variant="primary" @click="readDepot">Voir le dépôt</b-button>
				</div> -->
			</div>
        </div>
    </main>
</template>


<script type="text/javascript">
	import _debounce from 'lodash/debounce'
	import User from '@/mixins/User.js'
	import Tools from "@/mixins/Tools.js"

    export default {
		name: "ManageStallionNumber",
		mixins: [User, Tools],
		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
		},
		data() {
			return {
				debounceFetchUser: null,
				spinner: false,
				working: false,
				user_search: '',
				user_details: null,
				user_licencekey: '',
				entities: [],
				files: [],
				loading: false,
				carnet_status: [{code: 0, label:'A importer'}, {code: 1, label:'En cours'}, {code: 2, label:'Erreurs'}, {code: 3, label:'Pret pour depot IFCE'}, {code: 4, label:'Envoyé'}, {code: 5, label:'En attente'}]
			}
		},
		created() {
			this.debounceFetchUser = _debounce(this.fetchUser, 1000)
		},
		mounted() {
			this.init_component()
		},
		methods: {
			init_component() {

			},
			async fetchUser() {
				this.spinner = true

				const is_mail = this.regex_email.test(String(this.user_search).toLowerCase())
				const is_licencekey = this.regex_licencekey.test(String(this.user_search).toUpperCase())
				let entities = []

				if(is_mail) {
					this.user_details = await this.getUserGamme(this.user_search)
						.then(result => {
							return result.retour
						}).finally(() => {
							this.spinner = false
						})

					// Si l'adresse mail existe
					if(this.user_details) {
						Object.keys(this.user_details).forEach(key => {
							if(this.user_details[key].main === true) {
								this.user_licencekey = key
								return
							}
						})

						entities = await this.getUserEntities(this.user_search) || []
					}
				}
				else if(is_licencekey) {
					this.user_licencekey = this.user_search
					entities = await this.getLicenceEntities(this.user_search) || []
				}
				else {
					console.log("none")
				}

				this.entities = entities.map(entity => {
					if(entity.carnet) {
						entity.carnet.horsedpsstatus_status = this.carnet_status.find(status => status.code == entity.carnet.horsedpsstatus_status)
					}
					return entity
				})

				this.spinner = false
			},
			validForm() {
				this.working = true
				const to_save = this.entities.filter(entity => !!entity.tiers_stallion_number || !!entity.tiers_sanitaire)

				this.setEntityStallionId(this.user_licencekey, to_save)
					.then(res => {
						this.entities = res.map(entity => {
							if(entity.carnet) {
								entity.carnet.horsedpsstatus_status = this.carnet_status.find(status => status.code == entity.carnet.horsedpsstatus_status)
							}
							return entity
						})
						this.successToast()
					})
					.finally(() => {
						this.working = false
					})
			},
			async readDepot() {
				this.loading = true
				this.files = await this.getIFCEDepot()
				this.loading = false
			}
		},
		watch: {
			user_search(val) {
				this.debounceFetchUser()
			}
		}
    }
</script>
